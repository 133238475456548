import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionsAcServiceService {

  private baseUrl = environment.apiGatewayRetailUrl;

  constructor(private http: HttpClient) {}

  getAllPendingTransactionsAc(): Promise<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}/transactionAc/pending`;
    return firstValueFrom(this.http.post<any[]>(serviceURL,{},{ headers }))
  }

  getAllCompletedTransactionsAc(): Promise<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}/transactionAc/completed`;
    return firstValueFrom(this.http.post<any[]>(serviceURL,{},{ headers }))
  }

  getAllTransactionsAcByUser(id: any, body: any): Promise<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}/transactionAc/${id}?page=${body.page}&pageSize=${body.pageSize}`;
    return firstValueFrom(this.http.get<any>(serviceURL), body);
  }

  approveTransactionsAc(id: string, body:any): Promise<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}/transactionAc/${id}`;
    return firstValueFrom(this.http.post<any[]>(serviceURL,body,{ headers }))
  }

  createTransactionsAc(transaction: any): Promise<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}/transactionAc`;
    
    return firstValueFrom(this.http.post<any[]>(serviceURL,transaction,{ headers }))
  }

}

