import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Usuario } from '../../interfaces/transaccion.interface';
import { UserService } from '../../services/user.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { RutFormat, formatRut } from '@fdograph/rut-utilities';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransaccionService } from '../../services/transaccion.service';
import { formatDate } from 'src/app/shared/utils/date.util';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CurrencyPipe, DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { CartolaService } from '../../services/cartola.service';
import { ManualDepositComponent } from '../manual-deposit/manual-deposit.component';
import { MatDialog } from '@angular/material/dialog';
import { ParameterService } from '../../services/parameter.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { ParameterTypeEnum } from 'src/app/shared/enums/parameter-type.enum';
import { CreditLineService } from '../../services/credit-line.service';
import { LineaCredito } from '../../interfaces/credit-line.interface';
import { CreditLineDialogComponent } from '../credit-line-dialog/credit-line-dialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-balance',
  templateUrl: './users-balance.component.html',
  styleUrls: ['./users-balance.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UsersBalanceComponent {
  userId: string ='';
  userEmail: string = '';
  userInfo: Usuario | undefined;
  saldos: any[] = [];
  operatorId: string="";
  validUser: boolean=false;
  columnsToDisplay: string[] = ['fecha', 'descripcion', 'saldo', 'cargo', 'abono'];
  dataSource: MatTableDataSource<any> | undefined;
  expandedElement: any | null | undefined;
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'detalle'];
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(), [Validators.required]),
    end: new FormControl<Date | null>(new Date(), [Validators.required]),
  });
  saldoUsuario: number = 0;
  sobregiroDisponible :number|undefined=0;
  saldoContable :number|undefined= 0;
  lineaCreditoBloqueada:boolean|undefined=false;
  lineaCredito:number|undefined=0;
  lineaCreditoDisponible=false;
  lineaCreditoData:LineaCredito|undefined;
  columns = [
    {
      columnDef: 'fecha',
      header: 'Fecha',
      cell: (element: any) => `${this.datePipe.transform(element.fechaCreacion, 'dd/MM/yyyy HH:mm:ss')}`,
    },
    {
      columnDef: 'descripcion',
      header: 'Descripción',
      cell: (element: any) => `${this.getDescription(element)} `,
    },
    {
      columnDef: 'cargo',
      header: 'Cargo',
      cell: (element: any) => `${this.getCargo(element.motivo, element.monedaOrigen) } `,

    },
    {
      columnDef: 'saldo',
      header: 'Saldo',
      cell: (element: any) => `${this.currencyPipe.transform(element.saldo)} `,

    },
    {
      columnDef: 'abono',
      header: 'Abono',
      cell: (element: any) => `${this.getAbono(element.motivo, element.monedaOrigen)} `,
    }

  ]
  constructor(
    private currencyPipe:  CurrencyPipe,
    private datePipe: DatePipe,
    private transaccionService: TransaccionService,
    private route: ActivatedRoute,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private cartolaService: CartolaService,
    private auth: CognitoService,
    private parameterService:ParameterService,
    private dialog: MatDialog,
    private lineaCreditoService: CreditLineService,
    ) {
    this.userId = this.route.snapshot.params["userId"];
    this.userEmail = this.route.snapshot.params["userEmail"];
    this.obtenerSaldo();
    this.getUser();
    this.initFormCuenta();
    this.search();
  }

  async initFormCuenta() {
    const cognitoUser = await this.auth.getUser();
    this.operatorId = await cognitoUser.attributes['email'];
    this.operatorId = await this.generateCod(this.operatorId.toUpperCase().toString());
    const validEmails = await this.parameterService.getParameter(ParameterTypeEnum.CORREOS_AUTORIZADOS);
    console.log("valids:", validEmails.valor);
    if(validEmails.valor.includes(this.operatorId)){
      this.validUser=true;
    }
  }
  async generateCod(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  async obtenerSaldo(){
    const {saldo,lineaCreditoId,balancePermitido,lineaBloqueada,sobregiro} = await this.cartolaService.getSaldoCliente(this.userId);
    this.saldoUsuario = saldo;
    this.sobregiroDisponible=sobregiro;
    this.saldoContable = balancePermitido;
    this.lineaCreditoBloqueada=lineaBloqueada;
    this.lineaCredito=lineaCreditoId;
    if(this.lineaCredito&&this.lineaCredito>0&&!this.lineaCreditoBloqueada){
      this.lineaCreditoDisponible=true;
    }else{
      this.lineaCreditoDisponible=false;
    }
  }

  downloadFile() {
    const replacer = (key: any, value: null) => (value === null ? '' : value);
    const header = ['fecha', 'saldo', 'abono', 'debito','descripcion', 'ref'];
    const csv = this.saldos.map((row) =>
      header
        .map((fieldName) => {
          if(fieldName=== 'fecha'){
            return row['fechaCreacion']
          }
          if(fieldName === 'saldo'){
            return row['saldo']
          }
          if(fieldName === 'abono'){
            if(row.motivo ==='FONDEO WALLET'){
              return row['monedaOrigen']
            }else{
              return 0;
            }
          }
          if(fieldName === 'debito'){
            if(row.motivo ==='FONDEO WALLET'){
              return 0;
            }else{
              return row['monedaOrigen']
            }
          }
          if(fieldName === 'descripcion'){
            return row['banco']
          }
          if(fieldName === 'ref'){
            return row['codigo_transferencia']
          }
          return '';
        })

    );
    const data = [[...header], ...csv]

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });

    saveAs(new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' })], { type: 'application/octet-stream' }), 'datos.xlsx');

  }

  async getUser(){
    const ref = this.spinnerService.start();
    this.userService.getUserData(this.userEmail).subscribe((res: any)=>{
      this.userInfo = res.user;
      this.spinnerService.stop(ref);
    });
  }
  getDescription(data:any){
    if(data.motivo === 'FONDEO WALLET'){
      if(data.remitente?.trim()){
        return `Fondeo de Wallet de ${data.remitente?.trim() || data.pagador || 'Aguacate Wallet' }`;
      }
        return `Fondeo de Wallet manual`;

    }
    if(data.beneficiario!==null){
      return `Transferencia enviada a ${data.beneficiario} - ${data.tipoTransaccion}`
    }
    if(data.beneficiarioZelle!==null){
      return `Transferencia enviada a ${data.beneficiarioZelle} - ${data.tipoTransaccion}`
    }
    if(data.motivo.includes('Reversión')){
      return `Transferencia de Reversión`
    }
    if(data.motivo==='AJUSTE DE BALANCE'&&data.monedaOrigen<0){
      return `Debito manual`;
    }
    if(data.tipoTransaccion==='RETIRO'){
      return `Transaccion de ${data.tipoTransaccion}`;
    }
    if(data.tipoTransaccion!==''){
      return `Transaccion ${data.tipoTransaccion}`;
    }
    return `Movimiento sin Transaccion`
  }
  getCargo(motivo:string, monedaOrigen: number){
    if(motivo === 'FONDEO WALLET' ||  motivo.includes('Reversión')){
      return 0;
    }
    return this.currencyPipe.transform(Math.abs(monedaOrigen));
  }
  getAbono(motivo:string, monedaOrigen: number){
    if(motivo === 'FONDEO WALLET' ||  motivo.includes('Reversión')){
      return this.currencyPipe.transform(Math.abs(monedaOrigen));
    }
    return 0;
  }
  formatDocument(){
    if(this.userInfo?.tipoId === 'RUT' ){
      return formatRut(this.userInfo.numeroId, RutFormat.DOTS_DASH);
    }
    return this.userInfo?.numeroId;
  }

  async search() {
    const ref = this.spinnerService.start();
    const saldos = await this.transaccionService.getTransaccionClienteByDate(this.userEmail, formatDate(this.range.controls['start'].value), formatDate(this.range.controls['end'].value));
    this.saldos = saldos;
    this.spinnerService.stop(ref);
    this.dataSource = new MatTableDataSource(saldos);
  }

  async agregar(){
    this.dialog.open(ManualDepositComponent, {
      data: {userInfo: this.userInfo, saldo: this.saldoUsuario, tipo: "Agregar"},
      disableClose: false,
    }).afterClosed().subscribe(res=>{
      setTimeout(()=> {this.obtenerSaldo()}, 1000);
      this.obtenerSaldo();
    });
  }

  async debitar(){
    this.dialog.open(ManualDepositComponent, {
      data: {userInfo: this.userInfo, saldo: this.saldoContable, tipo: "Debitar"},
      disableClose: false,
    }).afterClosed().subscribe(res=>{
      setTimeout(()=> {this.obtenerSaldo()}, 1000);
      this.obtenerSaldo();
    });
  }

  async quitar(){
    this.dialog.open(ManualDepositComponent, {
      data: {userInfo: this.userInfo, saldo: this.saldoUsuario, tipo: "Quitar"},
      disableClose: false,
    }).afterClosed().subscribe(res=>{
      setTimeout(()=> {this.obtenerSaldo()}, 1000);
      this.obtenerSaldo();
    });
  }

  async getLineaCredito(){
    await this.lineaCreditoService.getByUser(this.userId)
    .then(lineaCredito => {
      if(lineaCredito){
        this.lineaCreditoData = lineaCredito;
      }
    })
    .catch(error => {
      console.error("Error recibiendo LineaCredito data:", error);
    });
  }

  async guardarLineaCredito(){
    if(this.lineaCreditoDisponible){
      await this.getLineaCredito();
    }
    const dialogRef = this.dialog.open(CreditLineDialogComponent, {
      width: '65%',
      maxWidth: '600px',
      data: { lineaCredito:this.lineaCreditoData, operator:this.operatorId, user:this.userId, userEmail:this.userEmail},
    });
    dialogRef.afterClosed().subscribe(() => {
      setTimeout(()=>10000);
      this.obtenerSaldo();
    });
  }

  async desactivarLineaCredito(){
    const ref = this.spinnerService.start();
    await this.lineaCreditoService.inactivateCreditLine(this.userId);
    this.lineaCreditoDisponible=false;
    this.spinnerService.stop(ref);
    Swal.fire('Linea de Crédito Desactivada', 'La linea de crédito ha sido desactivada', 'success');
    this.obtenerSaldo();
  }
}
