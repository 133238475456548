import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogCambioComponent } from '../../dialog-cambio/dialog-cambio.component';
import { TransactionUserDTO } from 'src/app/dashboard/interfaces/transaction-user.dto';

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
  styleUrls: ['./transaction-card.component.css']
})
export class TransactionCardComponent implements OnInit {
  @Input() transaccion: TransactionUserDTO | undefined;
  constructor(public dialog: MatDialog) {
  }

  public beneficiario: string | null = ''
  public status: string = ''

  ngOnInit() {
    this.status = this.transaccion!.estadoActual;
    if (this.transaccion?.motivo === 'FONDEO WALLET') {
      this.beneficiario = this.transaccion.pagador === null ? this.transaccion.remitente : this.transaccion.pagador;
      if (this.transaccion?.estadoActual === 'OPERACION EXITOSA') {
        this.status = 'ENVIADA'
      }
    } else {
      this.beneficiario = this.transaccion!.beneficiario;
    }
    if (this.transaccion?.motivo === 'AJUSTE DE BALANCE') {
      this.status = 'ENVIADA'
    }
    if (this.transaccion?.motivo === 'CRIPTOMONEDA' && this.transaccion?.beneficiario === null) {
      if (this.transaccion.walletTRC20) {
        this.beneficiario = "Transferencia a Wallet: " + this.transaccion.walletTRC20;
      } else {
        this.beneficiario = "Transferencia a Wallet"
      }
    }
    if (this.transaccion?.motivo === 'AC') {
      this.beneficiario = this.transaccion?.cuentaBeneficiario?.nombre + ' ' + this.transaccion.cuentaBeneficiario?.apellidos;
    }
    if (this.transaccion?.fechaCreacion) {
      const date = new Date(this.transaccion.fechaCreacion);
      const offset = -3;
      const localDate = new Date(date.setHours(date.getHours() + offset));
    
      this.transaccion.fechaCreacion = localDate;
    }
  }

  openDialog() {
    this.transaccion!.beneficiario = this.beneficiario!
    this.dialog.open(DialogCambioComponent, {
      data: this.transaccion
    });
  }

}
