<div class="p-5">
    <h1 class="text-xl text-center mt-8">Transacciones pendientes</h1>
    <div class="flex overflow-x-auto max-h-[60%]">
        <table mat-table [dataSource]="dataSource" class="my-4 w-5/6 m-h-[60%]" *ngIf="dataSource">
        
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center">{{ column.header }}</th>
                
                <td mat-cell *matCellDef="let row" style="text-align:center">
                  <ng-container *ngIf="column.columnDef === 'precio_compra'">
                    <ng-container *ngIf="row.isEditing; else readOnlyPrice">
                      <input [(ngModel)]="row.precio_compra" placeholder="Precio Compra" />
                    </ng-container>
                    <ng-template #readOnlyPrice>
                      {{ row.precio_compra }}
                    </ng-template>
                  </ng-container>
              
                  <ng-container *ngIf="column.columnDef === 'cuentaTrabajoID'">
                    <ng-container *ngIf="row.isEditing; else readOnlyCuentaTrabajo">
                      <input [(ngModel)]="row.cuentaTrabajoID" placeholder="Cuenta Trabajo ID" />
                    </ng-container>
                    <ng-template #readOnlyCuentaTrabajo>
                      {{ row.cuentaTrabajoID }}
                    </ng-template>
                  </ng-container>
              
                  <ng-container *ngIf="column.columnDef === 'voucher'">
                    <ng-container *ngIf="row.isEditing; else readOnlyVoucher">
                      <input [(ngModel)]="row.voucher" placeholder="Voucher" />
                    </ng-container>
                    <ng-template #readOnlyVoucher>
                      {{ row.voucher }}
                    </ng-template>
                  </ng-container>
              
                  <ng-container *ngIf="column.columnDef !== 'precio_compra' && column.columnDef !== 'cuentaTrabajoID' && column.columnDef !== 'voucher'">
                    {{ column.cell(row) }}
                  </ng-container>
                </td>
              </ng-container>
              
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center">Acciones</th>
                <td mat-cell *matCellDef="let row" style="text-align:center">
                  <button mat-button *ngIf="!row.isEditing" (click)="row.isEditing = true">Editar</button>
                  <button mat-button *ngIf="row.isEditing&&(row.precio_compra&&row.cuentaTrabajoID)" (click)="edit(row)">Guardar</button>
                  <button mat-button *ngIf="row.precio_compra&&row.cuentaTrabajoID" (click)="approve(row)">Aprobar</button>
                  <button mat-button (click)="refuse(row)">Rechazar</button>
                </td>
              </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      
    <button mat-raised-button color="primary" class="mx-8" (click)="reload()" >Refrescar</button>
  </div>