import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ActionsService } from 'src/app/shared/services/actions.service';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import { BinanceService } from 'src/app/dashboard/services/binance.service';
import { CartolaService } from 'src/app/dashboard/services/cartola.service';
import { HoursOperationService } from 'src/app/dashboard/services/hours-operation.service';
import { ParameterService } from 'src/app/dashboard/services/parameter.service';
import { BeneficiaryFiatComponent } from './components/beneficiary-fiat/beneficiary-fiat.component';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { CalculatorFiatCalComponent } from './components/calculator-fiat-cal/calculator-fiat-cal.component';
import { RatesAcService } from '../../services/rates-ac-service.service';

@Component({
  selector: 'app-calculator-fiat',
  templateUrl: './calculator-fiat.component.html',
  styleUrls: ['./calculator-fiat.component.sass']
})
export class CalculatorFiatComponent implements OnInit{

  isVertical= false;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }
  constructor(
    private cartolaService: CartolaService,
    private store: Store<AppState>,
    private binanceService: BinanceService,
    private actionService: ActionsService,
    private hourOperations: HoursOperationService,
    private router: Router,
    private spinner: SpinnerService,
    private parameterService: ParameterService,
    private ratesFiatService: RatesAcService
  ){}


  @ViewChild('beneficiarioComponent') beneficiaryComponent: BeneficiaryFiatComponent | undefined;

  @ViewChild('stepper') stepper: MatStepper | undefined;

  @ViewChild('calculatorComponent') calculatorComponent: CalculatorFiatCalComponent | undefined;

  public subscription: Subscription = new Subscription();
  public user: any;
  public saldoActual = 0;
  public sobregiroDisponible :number|undefined=0;
  public saldoContable :number|undefined= 0;
  public lineaCreditoBloqueada:boolean|undefined=false;
  public lineaCredito:number|undefined=0;
  public hasLoaded:boolean =  false;
  public ves_clp_tax: number = 0;
  public stepPass2: boolean = false;
  public stepPass3: boolean = false;
  public stepPass4: boolean = false;
  public clpCurrency: number = 0;
  public vesCurrency: number = 0;
  public beneficiario: CuentaBeneficiario | undefined;
  public valorCambioId: number = 0;
  public vesSellValue: number = 0;
  public zelleBuyValue: number = 0;
  public porCompZelle: number = 0;
  public descVentaZelle: number = 0;
  public spotPrice: number = 0;
  public limitFiatSell: number = 0;
  public minLimit: number = 100;

  public rateList: any[] = [];
  estadoOperaciones = true;
  estadoStopOperacion = true;
  ngOnInit(): void {
    this.onWindowResize();
    this.subscription.add(
      // this.actionService.subjectTasaUSDT.subscribe(res=>{
      //   this.getCurrentValues();
      //   if(this.stepper && this.stepper.selectedIndex !== 0){
      //     Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(()=>{});
      //     this.stepper.selectedIndex = 0;
      //   }
      // })
    )
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
      })
    );
    this.getCurrentValues();
    this.validarEstado();
  }


  async validarEstado(){
    this.estadoOperaciones = await this.hourOperations.getEstadoOperacion('AC');
    const date = new Date().getDay();
    const message = [6,7].includes(date) ? 'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos el Lunes a las 9:00 am' :
    'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos Mañana a las 9:00 am'
    console.log(date);
    if(!this.estadoOperaciones){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación cerrada',
        text:message,
      }
      );
      this.router.navigate(['/retail/home']);
    }
    if(!this.estadoStopOperacion){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación Pausada',
        text:'Volvemos en 10 min',
      }
      );
      this.router.navigate(['/retail/home']);
    }
  }

  async obtenerSaldo(){
    if(this.user?.usuarioId){
      const {saldo,lineaCreditoId,balancePermitido,lineaBloqueada,sobregiro} = await this.cartolaService.getSaldoCliente(this.user.usuarioId);
      this.saldoActual = saldo;
      this.sobregiroDisponible=sobregiro;
      this.saldoContable = balancePermitido;
      this.lineaCreditoBloqueada=lineaBloqueada;
      this.lineaCredito=lineaCreditoId;
    }
  }

  async getCurrentValues(){
    const ref = this.spinner.start()
    const currentValues = await this.ratesFiatService.getRatesByProfile('client');
    this.rateList = currentValues;
    this.ves_clp_tax = +currentValues[0].tasa;
    this.limitFiatSell = +currentValues[2].rango.split('-')[1];
    this.minLimit = +currentValues[0].rango.split('-')[0];
    console.log(currentValues);
    this.valorCambioId = currentValues[0].id;
    this.vesSellValue = +this.ves_clp_tax;
    ;
    this.spinner.stop(ref);
  }

  async returngetCurrentTax(){
    const currentValues = await this.binanceService.getConversionData();
    // if(currentValues.tasaUsdtClp != this.ves_clp_tax && this.ves_clp_tax != 0){
    //   this.getCurrentValues();
    //   if(this.stepper && this.stepper.selectedIndex !== 0){
    //     Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(()=>{});
    //     this.stepper.selectedIndex = 0;
    //     setTimeout(()=> this.calculatorComponent!.transformCurrency('clp'), 1000)
    //   }
    // }
  }

  async validateGetCurrentValues(){
    const currentValues =  await this.ratesFiatService.getRatesByProfile('client');
    // let last_tax = this.ves_clp_tax;
    // if(last_tax != currentValues.tasaUsdtClp){

    // }
    // this.ves_clp_tax = currentValues.tasaUsdtClp;
    // this.valorCambioId = currentValues.id;
  }


  nextStep( step:number ):void {
    console.log('Step:', step);
    switch( step ){
      case 2:{
        this.stepPass2 = true
        break
      }
      case 3:{
        this.stepPass3 = true
        break
      }
      case 4: {
        this.stepPass4 = true;
        break;
      }
    }
    setTimeout(() => {
      if (this.stepper) {
        console.log('Advancing to next step');
        this.stepper.next();
      } else {
        console.log('Stepper not defined');
      }
    }, 300);
  }

  backStep(): void {
    setTimeout(() => {
      if(this.stepper){
        this.stepper.previous();
      }
    }, 300);
  }

  assignClp( clp:number ){
    this.clpCurrency = clp;
    if(this.clpCurrency == 0) this.stepPass3 = false;
  }

  assignZelle( usd:number ){
    this.vesCurrency = usd;
    if(this.vesCurrency == 0) this.stepPass3 = false;
  }

  assignBeneficiario( beneficiario:CuentaBeneficiario ){
    this.beneficiario = beneficiario;
  }

  onStepChange(event: StepperSelectionEvent): void{
    this.returngetCurrentTax();
    console.log ('Step:', event.selectedIndex);

  }




}
