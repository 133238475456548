import { Component } from '@angular/core';
import { HoursOperationService } from '../../services/hours-operation.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ParameterService } from '../../services/parameter.service';
import { UserRoles } from 'src/app/shared/enums/roles-enum';

@Component({
  selector: 'app-hours-operations',
  templateUrl: './hours-operations.component.html',
  styleUrls: ['./hours-operations.component.sass']
})
export class HoursOperationsComponent {
  role = UserRoles.aguacate_operator;
  estadoOperacion = {
    FIAT : true,
    USDT: true,
    ZELLE: true,
    AC: true,
    FIAT_STOP: true,
    USDT_STOP: true,
    ZELLE_STOP: true,

  }

  estadoBinance = false;
  constructor(private readonly hoursOperationService: HoursOperationService ,private paramsService: ParameterService, private auth: CognitoService, private spinner: SpinnerService ){
    this.getEstadoOperacion('USDT');
    this.getEstadoOperacion('FIAT_STOP');
    this.getEstadoOperacion('USDT_STOP');
    this.getEstadoOperacion('FIAT');
    this.getEstadoOperacion('ZELLE_STOP');
    this.getEstadoOperacion('ZELLE');
    this.getEstadoOperacion('AC');
    this.getEstadoBinance();
  }

  async saveEstadoOperacion(activo: boolean, tipo:'FIAT'| 'USDT' | 'ZELLE' | 'ZELLE_STOP' |'FIAT_STOP' | 'USDT_STOP' | 'AC'){
    const ref = this.spinner.start();
    const cognitoUser = await this.auth.getUser();
    const email = cognitoUser.attributes.email;
    console.log(email);
    const body = {
      estado: activo,
      responsable_email: email,
      tipo

    }
    await this.hoursOperationService.saveEstadoOperacion(body);
    this.spinner.stop(ref);
    await this.getEstadoOperacion(tipo);

  }

  async getEstadoOperacion( tipo:'FIAT'| 'USDT' | 'ZELLE' | 'ZELLE_STOP' | 'FIAT_STOP' | 'USDT_STOP' | 'AC'){
    const ref = this.spinner.start();
    this.estadoOperacion[tipo] = (await this.hoursOperationService.getEstadoOperacion(tipo)) ?? false;
    this.spinner.stop(ref);

  }

  async getEstadoBinance(){
    const ref = this.spinner.start();
    this.estadoBinance = await this.paramsService.getParameter('STOP_BINANCE').then((res) => +res.valor === 1);
    console.log(this.estadoBinance);
    this.spinner.stop(ref);
  }

  async saveEstadoBinance(status: number){
    const ref = this.spinner.start();
    await this.paramsService.saveParameter('STOP_BINANCE', status.toString());
    this.estadoBinance = status === 1;
    this.spinner.stop(ref);
  }
}
