import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { RatesAcService } from '../../services/rates-ac-service.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rates-ac',
  templateUrl: './rates-ac.component.html',
  styleUrls: ['./rates-ac.component.sass']
})

export class RatesAcComponent implements OnInit {
  tasa: any;

  columns = [
    {
      columnDef: 'id',
      header: 'ID',
      cell: (element: any) => `${element.id}`,
    },
    {
      columnDef: 'nombre',
      header: 'Nombre',
      cell: (element: any) => `${element.nombre}`,
    },
    {
      columnDef: 'descripcion',
      header: 'Descripcion',
      cell: (element: any) => `${element.descripcion}`,
    },
    {
      columnDef: 'origen',
      header: 'Origen',
      cell: (element: any) => `${element.origen}`,
    },
    {
      columnDef: 'destino',
      header: 'Destino',
      cell: (element: any) => `${element.destino}`,
    },
    {
      columnDef: 'tasa',
      header: 'Tasa',
      cell: (element: any) => `${element.tasa}`,
    },
    {
      columnDef: 'rango',
      header: 'Rango',
      cell: (element: any) => `${element.rango}`,
    },
    {
      columnDef: 'perfil',
      header: 'Perfil',
      cell: (element: any) => `${element.perfil}`,
    },
    {
      columnDef: 'responsable',
      header: 'Responsable',
      cell: (element: any) => `${element.responsible}`,
    }
  ];
  displayedColumns = [...this.columns.map(column => column.columnDef), 'actions'];

  dataSource: MatTableDataSource<any> | undefined;
  tasas: any;

  constructor(private datePipe: DatePipe, private spinner: SpinnerService, private ratesAc: RatesAcService, private dialog: MatDialog
  ) {
  }
  ngOnInit(): void {
    this.initTasaValue();
  }

  async initTasaValue(): Promise<void> {
    const ref = this.spinner.start();
    this.tasas = await this.ratesAc.getAllRates();
    this.dataSource = new MatTableDataSource(this.tasas);
    this.spinner.stop(ref);
  }
  editRow(row: any) {
    this.dataSource?.data.forEach((r: any) => {
      if (r.perfil === row.perfil) {
        r.isEditing = true;
      }
    });
  }

  async saveRow(row: any) {
    const filteredRates = this.dataSource?.data?.filter((r: any) => r.perfil === row.perfil);
  
    if (filteredRates && filteredRates.length >= 3) {
      const body = {
        rate1: filteredRates[2],
        rate2: filteredRates[1],
        rate3: filteredRates[0]
      };
  
      try {
        const ref = this.spinner.start();
        await this.ratesAc.createRates(body);
        this.dataSource?.data.forEach(r => r.isEditing = false);
        this.spinner.stop(ref);
        this.reload();
      } catch (error) {
        console.error('Error saving data', error);
      }
    } else {
      console.warn('Not enough rates to save.');
    }
  }

  async reload(): Promise<void> {
    this.initTasaValue();
  }

}
