<div class="m-8">
    <div class="flex flex-col justify-between items-center bg-red">
      <div class="mb-10">
        <h1 class="text-xl font-bold">Elige beneficiario</h1>
      </div>
      <div class="w-full grid grid-cols-4 gap-4">
        <input class="inputSearch w-full col-span-4 md:col-span-3" (keyup)="buscar($event)" [(ngModel)]="searchTxt" placeholder="Buscar" type="text">
        <button mat-button class="w-full col-span-4 md:col-span-1 py-4" (click)="openCreateDialog()">
          <mat-icon color="primary" class="add-icon w-auto ">add_box</mat-icon>
          <span>Agregar Beneficiario</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="listaBeneficiariosFiltrados && listaBeneficiariosFiltrados.length > 0" class="flex flex-col gap-4 p-4">
      <div *ngFor="let beneficiario of listaBeneficiariosFiltrados" class="px-6 py-4 w-full bg-aguacate-bg rounded-lg " (click)="elegir(beneficiario)" [class.bg-aguacate-highlight]="beneficiarioEscogido === beneficiario">
        <div class="px-6 py-4 w-full bg-aguacate-bg rounded-lg " [class.bg-aguacate-highlight]="beneficiario?.seleccionado">
          <div class="flex flex-row justify-between items-center">
            <div class="text-sm md:text-base"><b>{{beneficiario?.nombre}} {{beneficiario?.apellidos}} </b></div>
          </div>
          <div class="text-sm md:text-base">
            <b> Banco {{beneficiario?.banco?.nombre | titlecase}}</b>
            <br><b>País:</b> {{beneficiario?.banco?.codigoPais}}
            <br> <b>Tipo de cuenta:</b> {{beneficiario?.tipoCuenta?.nombre}}
            <br> <b> Numero de cuenta:</b> {{beneficiario?.numeroCuenta}}
          </div>

        </div>
      </div>
  </div>
  <div class="flex flex-row gap-4 justify-center items-center my-7">
     <button
      type="button"
      mat-raised-button
      color="primary"
      class="mt-10 p-5 text-lg"
      (click)="emitChangeStep()"
      [disabled]="beneficiarioEscogido == undefined"
    >
     Continuar
    </button>
  </div>
