<div class="flex flex-col text-center">
    <h1 class="text-xl font-bold mb-4">Tipo de cambio</h1>
    <div class="flex flex-col xl:flex-row items-center justify-evenly">
        <div class="zelle_value font-bold min-w-[350px] w-full">
            <h1>{{'Comprar VES'}}</h1> <br>
            <p>
                <span>1 CLP = {{ ves_buy_rate | number:'1.0-5'}} VES</span>
            </p>
            <img *ngIf="!hasLoaded" src="assets/loader.svg" height="60" width="60" class="mt-3 mx-auto" alt="">
        </div>

    </div>
</div>
