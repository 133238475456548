import { Component,Output,EventEmitter,Input } from '@angular/core';
import { ActionsService } from 'src/app/shared/services/actions.service';

@Component({
  selector: 'app-calculator-fiat-cal',
  templateUrl: './calculator-fiat-cal.component.html',
  styleUrls: ['./calculator-fiat-cal.component.sass']
})
export class CalculatorFiatCalComponent {
  public minError = false;
  public maxError = false;
  public clpCurrency: number = 0
  public vesCurrency: number = 0;
  public minValue: number = 100;
  public maxValue: number = 5000;

  @Input() rateList: any[] = [];

  @Input()
  public ves_clp_tax:number = 0;

  @Input()
  public ves_buy_rate: number = 0;






  _limitSell: number = 0;

  @Input() set limitSell(value : number){
    this._limitSell = value;
    this.maxError = false;
    if(this.clpCurrency > this._limitSell){
      this.maxError = true;
    }
  }

  @Input() set minLimit(value : number){
    this.minValue = value;
    this.minError = false;
    if(this.clpCurrency < this.minValue){
      this.minError = true
    }
  }
  @Output()
  public changeStep = new EventEmitter<number>();

  @Output()
  public clpCurrencyEmit = new EventEmitter<number>();

  @Output()
  public vesCurrencyEmit = new EventEmitter<number>();

  @Output()
  public goBack = new EventEmitter<any>();

  constructor(
    private actionService: ActionsService,
  ){
    this.actionService.subjectTasaUSDT.subscribe(
      (res)=>{
        // setTimeout(()=> this.transformCurrency('clp'), 1000)
      }
    )
  }

  get valorClp(): string {
    return this.clpCurrency === 0 ? '' : this.clpCurrency.toLocaleString('en-US');
  }

  set valorClp(val: string) {
    if (val === '') {
      this.clpCurrency = 0;
    } else {
      this.clpCurrency = parseFloat(val.replace(/,/g, ''));
    }
  }

  get valorFiat(): string{
    return this.vesCurrency === 0 ? '' : this.vesCurrency.toLocaleString('en-US');
  }

  set valorFiat(val: string) {

    if (val === '') {
      this.vesCurrency = 0;
    } else {
      this.vesCurrency = parseFloat(val.replace(/,/g, ''));
    }

  }

  emitChangeStep( ): void{
    if(this.minError)return;
    this.changeStep.emit( 3 );
    this.clpCurrencyEmit.emit( this.clpCurrency );
    this.vesCurrencyEmit.emit( this.vesCurrency );
  }



  clearValAvailableCLP( ): void{
      this.valorClp="0";
      this.transformCurrency('clp');
  }

  transformCurrency( currency: string ): void {
    this.minError=false;
    this.maxError=false;
    const rate = this.rateList.find(rate => rate.nombre === 'CLP-BS' && this.clpCurrency >= parseFloat(rate.rango.split('-')[0]) && this.clpCurrency <= parseFloat(rate.rango.split('-')[1]));
    if (rate) {
      this.ves_buy_rate = parseFloat(rate.tasa);
    }
    if(currency === 'clp'){
      this.vesCurrency = this.clpCurrency * this.ves_buy_rate;
    }
    if(currency === 'fiat'){
      this.clpCurrency = this.vesCurrency / this.ves_buy_rate;
    }
    if(this.clpCurrency < this.minValue){
      this.minError = true;
    }
    if(this.clpCurrency > this._limitSell){
      this.maxError = true;
    }
    if(this.minError)return;
    this.clpCurrencyEmit.emit(this.clpCurrency);
    this.vesCurrencyEmit.emit(this.vesCurrency);
  }

  soloNumeros(event: KeyboardEvent) {
    const char = event.key;
    if (!char.match(/[0-9]/)) {
      event.preventDefault();
    }
  }

  goToBack(): void {
    this.goBack.emit();
  }
}
