<div class="w-full p-5 md:p-0 md:w-1/2 mx-auto mt-10">
  <h1 class="text-2xl font-bold text-center"> Horario de operaciones </h1>
  <br>

  <div class="flex flex-row justify-center items-center mx-5">
    <div class="balance_container items-center">
        <ng-container *ngIf="role !== 'aguacate_retail_cashier'">
        <p class="font-bold">La operación para FIAT actualmente está {{estadoOperacion.FIAT === true ? 'Activa' : 'Cerrada'}}</p>
        <div class="flex flex-row justify-center items-center gap-4 mx-5 mt-8">
          <button mat-raised-button color="warn" class="p-5 text-base  py-9 w-1/2" [disabled]="!estadoOperacion.FIAT" (click)="saveEstadoOperacion(false, 'FIAT')">
            Cerrar Operación FIAT
          </button>
          <button  mat-raised-button color="primary" (click)="saveEstadoOperacion(true, 'FIAT')" [disabled]="estadoOperacion.FIAT" class=" w-1/2 p-5 py-9 text-base">
            Activar Operación FIAT
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="role !== 'aguacate_retail_cashier'">
        <p class="font-bold mt-4">La operación para USDT actualmente está {{estadoOperacion.USDT === true ? 'Activa' : 'Cerrada'}}</p>
        <div class="flex flex-row justify-between items-center gap-4 mx-5 mt-8">
          <button mat-raised-button color="warn" class="p-5 text-base  py-9 w-1/2 " [disabled]="!estadoOperacion.USDT" (click)="saveEstadoOperacion(false, 'USDT')">
            Cerrar Operación USDT
          </button>
          <button  mat-raised-button color="primary" (click)="saveEstadoOperacion(true, 'USDT')" [disabled]="estadoOperacion.USDT" class=" w-1/2 p-5 py-9 text-base">
            Activar Operación USDT
          </button>
       </div>
      </ng-container>

      <ng-container *ngIf="role !== 'aguacate_retail_cashier'">
       <p class="font-bold mt-4">La operación para ZELLE actualmente está {{estadoOperacion.ZELLE === true ? 'Activa' : 'Cerrada'}}</p>
       <div class="flex flex-row justify-between items-center gap-4 mx-5 mt-8">
         <button mat-raised-button color="warn" class="p-5 text-base  py-9 w-1/2 " [disabled]="!estadoOperacion.ZELLE" (click)="saveEstadoOperacion(false, 'ZELLE')">
          Cerrar Operación ZELLE
        </button>
         <button  mat-raised-button color="primary" (click)="saveEstadoOperacion(true, 'ZELLE')" [disabled]="estadoOperacion.ZELLE" class=" w-1/2 p-5 py-9 text-base">
          Activar Operación ZELLE
        </button>
      </div>
      </ng-container>
      <ng-container *ngIf="role !== 'aguacate_retail_cashier'">
       <p class="font-bold mt-4">El envío por binance actualmente está {{estadoBinance === true ? 'Activo' : 'Cerrado'}}</p>
       <div class="flex flex-row justify-between items-center gap-4 mx-5 mt-8">
         <button mat-raised-button color="warn" class="p-5 text-base  py-9 w-1/2 " [disabled]="!estadoBinance" (click)="saveEstadoBinance(0)">
           Cerrar envío por binance
         </button>
         <button  mat-raised-button color="primary" (click)="saveEstadoBinance(1)" [disabled]="estadoBinance" class=" w-1/2 p-5 py-9 text-base">
            Activar envío por binance
         </button>
      </div>
      </ng-container>
      <ng-container>
        <p class="font-bold mt-4">La operación para DETAL actualmente está {{estadoOperacion.AC === true ? 'Activo' : 'Cerrado'}}</p>
        <div class="flex flex-row justify-between items-center gap-4 mx-5 mt-8">
          <button mat-raised-button color="warn" class="p-5 text-base  py-9 w-1/2 " [disabled]="!estadoOperacion.AC" (click)="saveEstadoOperacion(false, 'AC')">
            Cerrar Operación DETAL
          </button>
           <button  mat-raised-button color="primary" (click)="saveEstadoOperacion(true, 'AC')" [disabled]="estadoOperacion.AC" class=" w-1/2 p-5 py-9 text-base">
            Activar Operación DETAL
          </button>
       </div>
       </ng-container>

  </div>

  </div>

</div>

<hr class="mt-5">
<div class="w-full p-5 md:p-0 md:w-1/2 mx-auto mt-10" *ngIf="role !== 'aguacate_retail_cashier'">
  <h1 class="text-2xl font-bold text-center"> Stop de operaciones </h1>
  <br>

  <div class="flex flex-row justify-center items-center mx-5">
    <div class="balance_container items-center">
        <p class="font-bold">La operación para FIAT actualmente está {{estadoOperacion.FIAT_STOP === true ? 'Activa' : 'Pausada'}}</p>
        <div class="flex flex-row justify-center items-center gap-4 mx-5 mt-8">
          <button mat-raised-button color="warn" class="p-5 text-base py-9 w-1/2" [disabled]="!estadoOperacion.FIAT_STOP" (click)="saveEstadoOperacion(false, 'FIAT_STOP')">
            Pausar Operación FIAT
          </button>
          <button  mat-raised-button color="primary" (click)="saveEstadoOperacion(true, 'FIAT_STOP')" [disabled]="estadoOperacion.FIAT_STOP" class=" w-1/2 p-5 py-9 text-base">
            Reanudar Operación FIAT
          </button>
        </div>
        <p class="font-bold mt-4">La operación para USDT actualmente está {{estadoOperacion.USDT_STOP === true ? 'Activa' : 'Pausada'}}</p>
        <div class="flex flex-row justify-between items-center gap-4 mx-5 mt-8">
          <button mat-raised-button color="warn" class="p-5 text-base  py-9 w-1/2" [disabled]="!estadoOperacion.USDT_STOP" (click)="saveEstadoOperacion(false, 'USDT_STOP')">
            Pausar Operación USDT
          </button>
          <button  mat-raised-button color="primary" (click)="saveEstadoOperacion(true, 'USDT_STOP')" [disabled]="estadoOperacion.USDT_STOP" class=" w-1/2 p-5 py-9 text-base">
            Reanudar Operación USDT
          </button>
       </div>
       <p class="font-bold mt-4">La operación para ZELLE actualmente está {{estadoOperacion.ZELLE_STOP === true ? 'Activa' : 'Pausada'}}</p>
        <div class="flex flex-row justify-between items-center gap-4 mx-5 mt-8">
          <button mat-raised-button color="warn" class="p-5 text-base  py-9 w-1/2" [disabled]="!estadoOperacion.ZELLE_STOP" (click)="saveEstadoOperacion(false, 'ZELLE_STOP')">
            Pausar Operación ZELLE
          </button>
          <button  mat-raised-button color="primary" (click)="saveEstadoOperacion(true, 'ZELLE_STOP')" [disabled]="estadoOperacion.ZELLE_STOP" class=" w-1/2 p-5 py-9 text-base">
            Reanudar Operación ZELLE
          </button>
       </div>
  </div>

  </div>

</div>
