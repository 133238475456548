<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div class="container flex flex-col items-center" *ngIf="step === 1">
    <h1 class="text-2xl font-bold"> Configurar MFA </h1>
    <h2>Escánea este código con Google Authenticator</h2>
  <qrcode *ngIf="qrData" [qrdata]="qrData" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
  <div class = "flex justify-center">
    <button mat-button> Atrás</button>
    <button mat-button color="primary" (click)="nextStep(1)"> Siguiente</button>
  </div>
  </div>

  <div class="container flex flex-col items-center" *ngIf="step === 2">
    <h1 class="text-2xl font-bold"> Configurar MFA </h1>
    <h2>Ingresa el código generado por la aplicación</h2>
    <mat-form-field>
      <mat-label>Código</mat-label>
      <input matInput 
       type="text" 
       [(ngModel)]="code" 
       placeholder="Ingresa el código" 
       maxlength="6"
       pattern="[0-9]*"
       (keypress)="validateNumberInput($event)">
    </mat-form-field>
    <button mat-button> Atrás</button>
    <button mat-button color="primary" (click)="nextStep(2)"> Siguiente</button>
  </div>
  </div>



