<div class="px-6 py-4 w-full bg-aguacate-bg rounded-lg " *ngIf="transaccion">
  <button mat-icon-button (click)="openDialog()" aria-label="Detalles" class="md:hidden self-end">
    <mat-icon>more_vert</mat-icon>
  </button>
  <div class="flex-flex-row">
    <div class="flex flex-row justify-start items-center gap-11 lg:gap-8">
      <img *ngIf="transaccion?.motivo === 'FONDEO WALLET' || transaccion.motivo?.includes('Reversión')"
        class="md:block hidden imgTrasacton" src="./assets/icons/receive.png">
      <img *ngIf="transaccion?.motivo !== 'FONDEO WALLET' && !transaccion.motivo?.includes('Reversión')"
        class="md:block hidden imgTrasacton" src="./assets/icons/send.png">
      <div class="flex flex-col w-10/12">
        <div class="flex flex-row justify-between">
          <p class="self-start font-bold" style="word-break:keep-all; overflow-wrap: normal; overflow: hidden;">
            {{transaccion.motivo!=='AC'? (beneficiario | uppercase) : ''}}<span style="word-break: keep-all; overflow-wrap: normal; overflow: hidden;"
              *ngIf="transaccion.tipoTransaccion!=='RETIRO' && transaccion.motivo!=='AC'"><span *ngIf="beneficiario"> -</span> {{transaccion.motivo |
              uppercase}}</span> <span *ngIf="transaccion.motivo==='AC'">Transaccion Ves</span><span
              *ngIf="transaccion.tipoTransaccion==='RETIRO'">{{transaccion.tipoTransaccion}}</span>
          </p>
          <div class="hidden md:flex w-[300px] flex-row items-center align-middle justify-end gap-3">
            <b class="self-end my-auto" *ngIf="transaccion.estadoActual">{{transaccion.estadoActual.replaceAll('_', '
              ')}}</b>
            <span class="dot" *ngIf="status" [ngClass]="status.replaceAll(' ', '_')"></span>
            <button mat-icon-button (click)="openDialog()" aria-label="Detalles">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <p class="transaction-detail font-semibold">{{transaccion.fechaCreacion | date: 'medium'}}</p>
        </div>
        <div>
          <div class="flex lg:hidden w-[220px]  flex-row items-center align-middle justify-start gap-3"> <b
              class="self-end" *ngIf="transaccion.estadoActual">{{transaccion.estadoActual.replaceAll('_', '
              ')}}</b><span class="dot" [ngClass]="status"></span></div>
          <br>
          <p class="transaction-detail">{{transaccion.numeroCuenta}}</p>
          <div
            *ngIf="transaccion.motivo === 'FONDEO WALLET'&&transaccion.tipoTransaccion!=='RETIRO'&&transaccion.tipoTransaccion!=='AC'; else envia">
            <b>Monto:</b> {{transaccion.monedaOrigen | currency:'USD':'$'}}{{ " CLP"}}<br>
          </div>
          <div *ngIf="transaccion.motivo === 'AC'">
            <b>Numero de cuenta:</b> {{transaccion.cuentaBeneficiario?.numeroCuenta}}<br>
          </div>
          <ng-template #envia>
            <div *ngIf="transaccion.tipoTransaccion!=='RETIRO'&&!transaccion.motivo?.includes('Reversión transacción')">
              <b *ngIf="transaccion.motivo!=='AJUSTE DE BALANCE'">Envia:</b><b
                *ngIf="transaccion.motivo==='AJUSTE DE BALANCE'&&transaccion.tipoTransaccion!=='RETIRO'">Valor:</b>
              {{transaccion.monedaOrigen | currency:'USD':'$'}}{{ " " + transaccion.tipoMonedaOrigen}}<br>
            </div>
            <div *ngIf="transaccion.tipoTransaccion!=='RETIRO'&&transaccion.motivo?.includes('Reversión transacción')">
              <b *ngIf="transaccion.motivo!=='AJUSTE DE BALANCE'">Recibe:</b><b
                *ngIf="transaccion?.motivo==='AJUSTE DE BALANCE'&&transaccion.tipoTransaccion!=='RETIRO'">Valor:</b>
              {{transaccion.monedaOrigen | currency:'USD':'$'}}{{ " " + transaccion.tipoMonedaDestino}}<br>
            </div>
          </ng-template>
          <div *ngIf="transaccion.tipoTransaccion!=='RETIRO'&&transaccion.tipoTransaccion!=='AC'">
            <b *ngIf="transaccion.monedaDestino !== null">Recibe:</b> <label *ngIf="transaccion.monedaDestino">
              {{transaccion.monedaDestino | currency:'USD':'$'}}{{ " " + transaccion.tipoMonedaDestino}}</label>
          </div>
          <div *ngIf="transaccion.tipoTransaccion === 'RETIRO'">
            <b>Cuenta de retiro:</b> {{transaccion.aliasRetiro }}<br>
            <b>Banco:</b> {{transaccion.banco}}<br>
            <b>Numero de cuenta:</b> {{transaccion.numeroCuentaRetiro}}<br>
            <b>Monto de retiro:</b> {{transaccion.monedaDestino | currency:'USD':'$'}}{{ " CLP"}}<br>
          </div>
          <div *ngIf="transaccion.tipoTransaccion ==='ZELLE'">
            <b>Email/Telefono:</b> {{transaccion.direccionZelle }}<br>
            <b>Beneficiario Zelle:</b> {{transaccion.beneficiarioZelle}}<br>
          </div>

        </div>

      </div>

    </div>

  </div>


</div>