import { Component, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ComplianceService } from '../../services/compliance.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Elementos por página';
  override nextPageLabel = 'Siguiente';
  override previousPageLabel = 'Anterior';
  override firstPageLabel="Primera página";
  override lastPageLabel="Última página"

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  };
}

@Component({
  selector: 'app-compliance-list',
  templateUrl: './compliance-list.component.html',
  styleUrls: ['./compliance-list.component.css']
})
export class ComplianceListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'usuarioId',
    'email',
    'tipoId',
    'numeroId',
    'tipoUsuario',
    'nombre',
    'apellidos',
    'paisResidencia',
    'total_transferido',
    'limite_alcanzado',
    'esPoliticamenteExpuesto',
    'esRTP',
    'esListaONU',
    'KYC1',
    'KYC2',
    'estado_compliance',
    'fecha_creacion',
    'fecha_actualizacion',
    'responsable',
    'motivo_bloqueo',
    'dias_bloqueo',
    'acciones'

  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null = null;
  columns = [
    {
      columnDef: 'usuarioId',
      header: 'ID',
      cell: (element: any) => `${element.id}`,
      headerStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'min-width':'60px'},
      cellStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'min-width':'60px'},
      isSticky: true,
    },
    {
      columnDef: 'tipoUsuario',
      header: 'Tipo',
      cell: (element: any) => `${element.tipoUsuario}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'120px'},
      cellStyle: { 'text-align':'left','min-width':'120px'},
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: any) => `${element.email}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'350px'},
      cellStyle: { 'text-align':'left', 'min-width':'350px'},
    },
    {
      columnDef: 'paisResidencia',
      header: 'País',
      cell: (element: any) => `${element.paisResidencia}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'100px'},
      cellStyle: { 'text-align':'left', 'min-width':'100px'},
    },
    {
      columnDef: 'tipoId',
      header: 'Tipo Id',
      cell: (element: any) => `${element.tipoUsuario==='PERSONA'?element.tipoId:'Rut Empresa'}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'120px'},
      cellStyle: { 'text-align':'left', 'min-width':'120px'},
    },
    {
      columnDef: 'numeroId',
      header: 'Documento',
      cell: (element: any) => `${element.tipoUsuario==='PERSONA'?element.numeroId:element.rutEmpresa}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'150px'},
      cellStyle: { 'text-align':'left','min-width':'150px'},
    },
    {
      columnDef: 'nombre',
      header: 'Nombre',
      cell: (element: any) => `${element.tipoUsuario==='PERSONA'?element.nombre:element.razonSocial}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'150px'},
      cellStyle: { 'text-align':'left','min-width':'150px'},
    },
    {
      columnDef: 'apellidos',
      header: 'Apellidos',
      cell: (element: any) => `${element.tipoUsuario==='PERSONA'?element.apellidos:''}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'150px'},
      cellStyle: { 'text-align':'left','min-width':'150px'},
    },
    {
      columnDef: 'total_transferido',
      header: 'Total transferido',
      cell: (element: any) => `0`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'100px'},
      cellStyle: { 'text-align':'left','min-width':'100px'},
    },
    {
      columnDef: 'limite_alcanzado',
      header: 'Limite alcanzado',
      cell: (element: any) => `NO`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'100px'},
      cellStyle: { 'text-align':'center','min-width':'100px'},
    },
    {
      columnDef: 'esPoliticamenteExpuesto',
      header: 'PEP',
      cell: (element: any) => `${element.esPoliticamenteExpuesto}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'60px'},
      cellStyle: { 'text-align':'left','min-width':'60px'},
    },
    {
      columnDef: 'esRTP',
      header: 'RTP',
      cell: (element: any) => `${element.esRTP}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'60px'},
      cellStyle: { 'text-align':'left','min-width':'60px'},
    },
    {
      columnDef: 'esListaONU',
      header: 'Lista ONU',
      cell: (element: any) => `${element.esListaONU}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'60px'},
      cellStyle: { 'text-align':'left','min-width':'60px'},
    },
    {
      columnDef: 'KYC1',
      header: 'KYC 1',
      cell: (element: any) => ` `,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'60px'},
      cellStyle: { 'text-align':'left','min-width':'60px'},
    },
    {
      columnDef: 'KYC2',
      header: 'KYC 2',
      cell: (element: any) => ``,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'60px'},
      cellStyle: { 'text-align':'left','min-width':'60px'},
    },
    {
      columnDef: 'estado_compliance',
      header: 'Estado compliance',
      cell: (element: any) => {
        switch (element.estadoCompliance) {
          case 'POR APROBAR':
            return 'report';
          case 'RECHAZADO':
            return 'cancel';
          case 'BLACK LIST':
            return 'remove_circle';
          case 'APROBADO':
            return 'verified_user';
          default:
            return '';
        }
      },
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'120px'},
      cellStyle: { 'text-align':'center','min-width':'120px', 'display': 'list-item'},
      iconColor: (element: any) => {
        switch (element.estadoCompliance) {
          case 'POR APROBAR':
            return '#EDD402';
          case 'RECHAZADO':
            return 'red';
          case 'BLACK LIST':
            return 'black';
          case 'APROBADO':
            return 'green';
          default:
            return '';
        }
      },
      val: (element: any) => `${element.estadoCompliance}`,
    },
    {
      columnDef: 'motivo_bloqueo',
      header: 'Motivo bloqueo',
      cell: (element: any) => ``,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'120px'},
      cellStyle: { 'text-align':'left','min-width':'120px'},
    },
    {
      columnDef: 'dias_bloqueo',
      header: 'Días bloqueo',
      cell: (element: any) => ``,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'120px'},
      cellStyle: { 'text-align':'left','min-width':'120px'},
    },
    {
      columnDef: 'fecha_actualizacion',
      header: 'Ultima actualizacion',
      cell: (element: any) => {
        try {
          if (element?.log && element.estadoCompliance !== "POR APROBAR") {
            const parsedLog = JSON.parse(element.log);
            return parsedLog.hora ? new Date(parsedLog.hora).toLocaleString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }) : 'N/A';
            
          } else {
            return 'N/A';
          }
        } catch (error) {
          console.error('Error parsing log:', error);
          return 'N/A';
        }
      },
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'140px'},
      cellStyle: { 'text-align':'left','min-width':'140px'},
    },
    {
      columnDef: 'fecha_creacion',
      header: 'Fecha de creacion',
      cell: (element: any) => {
        try {
          if (element?.log && element.estadoCompliance === "POR APROBAR") {
            const parsedLog = JSON.parse(element.log);
            return parsedLog.hora ? new Date(parsedLog.hora).toLocaleString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }) : 'N/A';
            
          } else {
            return element?.created_at ? new Date(element.created_at).toLocaleString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false,
            }) : 'N/A';;
          }
        } catch (error) {
          console.error('Error parsing log:', error);
          return '';
        }
      },
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'140px'},
      cellStyle: { 'text-align':'left','min-width':'140px'},
    },
    {
      columnDef: 'responsable',
      header: 'Responsable',
      cell: (element: any) => {
        try {
          if (element?.log && element.estadoCompliance !== "POR APROBAR") {
            const parsedLog = JSON.parse(element.log);
            return parsedLog.responsable ? parsedLog.responsable : 'N/A';
          } else {
            return 'N/A';
          }
        } catch (error) {
          console.error('Error parsing log:', error);
          return 'N/A';
        }
      },
      headerStyle: { 'text-align':'left', 'font-weight': 'bold','min-width':'350px'},
      cellStyle: { 'text-align':'left','min-width':'350px'},
    },

  ];
  dataSource: MatTableDataSource<any> | undefined;
  userList = [];
  subscription: Subscription = new Subscription();
  selectedFilter: string = '';
  filters: string[] = ['EMAIL', 'ID'];
  searchValue: string = '';
  defaultPageSize = 25;
  filterOptions: string[] = ['POR APROBAR', 'APROBADO', 'RECHAZADO'];
  constructor(private complianceService: ComplianceService) {
    this.subscription.add(this.complianceService.getUserComplianceList().subscribe(
      ({ users }: any) => {
        this.dataSource = new MatTableDataSource(users);
        this.userList = users;
        if (this.dataSource) {
          this.dataSource.paginator = this.paginator;
        }
      }
    ));
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {

  }

  isColumnSticky(columnDef: string): boolean {
    return columnDef === 'usuarioId' || columnDef === 'email';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
  }

  public doFilter = (event: any | string) => {
    if (typeof event === 'string') {
      if (this.dataSource){
          this.dataSource.filter = event.trim().toLocaleLowerCase();
        }
        return;
    }
  
    const element = (event as KeyboardEvent).currentTarget as HTMLInputElement;
    const value = element.value;
    if (this.dataSource){
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
  }

  editar(row: any){
  }

}
