import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree, createUrlTreeFromSnapshot } from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class RetailGuard implements CanActivate, CanActivateChild {
  constructor(private cognitoService: CognitoService, private router: Router){

  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log("Calling canActivate");
      this.cognitoService.getAuthUser().then(res=>{
        console.log(res)
      })
      this.cognitoService.getSession().then(res=>{
        console.log(res);
      })
    return from(this.cognitoService.getUser()).pipe(map((userInfo: any)=>{
      console.log('from interceptor: ',userInfo);
      const userGroup = userInfo?.attributes?.['custom:user-group'];
      console.log('userGroup: ', userGroup);
      if (userInfo?.attributes?.email && (userGroup === 'aguacate_retail_cashier' || userGroup === 'aguacate_retail_client')) {
        return true;
      } else {
        return createUrlTreeFromSnapshot(route, ['/auth/login']);
      }
    }));

}
}
