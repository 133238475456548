import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { S3 } from 'aws-sdk';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { TransactionsAcServiceService } from 'src/app/retail/services/transactions-ac-service.service';
import { ErrorTypes } from 'src/app/shared/enums/error-types.enum';
import { TipoPago } from 'src/app/shared/enums/tipo-pago.enum';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';


@Component({
  selector: 'app-summary-order-fiat',
  templateUrl: './summary-order-fiat.component.html',
  styleUrls: ['./summary-order-fiat.component.sass']
})
export class SummaryOrderFiatComponent {
  constructor(
    private spinnerService: SpinnerService,
    private transaccionService: TransactionsAcServiceService,
    private router: Router,
    private actionService: ActionsService,
    private fb: UntypedFormBuilder,
  ) {
    this.datosForm = this.fb.group({
      photo: []

    });
  }
  contentType = 'image/jpeg';
  fileExt = 'jpeg';
  comprobante: any;
  datosForm: FormGroup | undefined;
  @Input()
  public clpCurrency: number = 0;

  @Input()
  public vesCurrency: number = 0;

  @Input()
  public ves_clp_tax: number = 0;

  @Input()
  public valorCambioId: number = 0;

  @Input()
  public beneficiario: CuentaBeneficiario | undefined;

  @Output()
  public goBack = new EventEmitter<any>();
  setFileData(event: Event): void {
    const eventTarget: HTMLInputElement | null = event.target as HTMLInputElement | null;
    if (eventTarget?.files?.[0]) {
      const file: File = eventTarget.files[0];
      this.contentType = file.type;
      const filename = file.name;
      this.fileExt = filename.split('.').pop() || '';
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.datosForm?.get('photo')?.setValue(reader.result as string);
      });

      reader.readAsDataURL(file);
    }
  }

  uploadBase64Image(base64Image: string): Promise<any> {
    const buffer = Buffer.from(base64Image.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    this.comprobante = `paymentProof/${new Date().getTime()}.${this.fileExt}`;
    const s3 = new S3({

      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
    });
    const params = {
      Bucket: environment.s3.bucket,
      Key: this.comprobante,
      Body: buffer,
      ContentEncoding: 'base64',
      ContentType: this.contentType
    };

    return s3.upload(params).promise();
  }

  async confirmarEnvio() {
    const ref = this.spinnerService.start();
    let comprobante = undefined;
    if (this.datosForm?.contains('photo') && !this.datosForm?.get('photo')?.value) {
      Swal.fire('Error al enviar dinero', 'Debe subir un comprobante de pago', 'warning');
      this.spinnerService.stop(ref);
      return;
    }
    const response = await this.uploadBase64Image(this.datosForm?.get('photo')?.value);
    comprobante = this.comprobante;
    const transaccionDTO = {
      "paisOrigen": 'CL',
      "userId": this.beneficiario?.usuarioID,
      "totalMonedaOrigen": + this.clpCurrency,
      "totalMonedaDestino": +this.vesCurrency,
      "monedaOrigen": 'CLP',
      "monedaDestino": 'VES',
      "paisDestino": 'VE',
      "formaPago": TipoPago.TRANSFERENCIA,
      "diferencia": null,
      "tasa": this.ves_clp_tax,
      "cuentaBeneficiarioId": this.beneficiario?.cuentaBeneficiarioID,
      "tasaCambioId": null,
      "valorCambioId": this.valorCambioId!,
      "tipoTransaccion": 'AC',
      "voucher": comprobante
    }

    try {
      const resultadoTransaccion = await this.transaccionService.createTransactionsAc(transaccionDTO);
      this.spinnerService.stop(ref);
      Swal.fire('Transaccion creada', `Se ha creado la transaccion con éxito`, 'success');
      this.router.navigate(['/retail/home']);

    } catch (error: any) {
      if (error.status === 400 && error.error.type === ErrorTypes.TASAS_ACTUALIZADAS) {
        this.spinnerService.stop(ref);
        this.actionService.emitirAccionTasaUSDT();
      } else {
        Swal.fire('Error al enviar dinero', 'Ha ocurrido un error al realizar la transferencia', 'warning').then(() => {
          this.spinnerService.stop(ref);
          this.router.navigate(['/retail/home']);
        });
      }

    }
  }

  goToBack(): void {
    this.goBack.emit();
  }
}
