<div class="container_change">
  <app-fiat-change [ves_buy_rate]="ves_buy_rate"  [showBuy]="false" class="zelleChange"></app-fiat-change>
    <div class="text-center">
      <h2 class="font-bold">Horario de operación: 9:00 - 17:00 </h2>
    </div>
    <div class="mt-10">
      <div class="grid grid-cols-1 send-input">
        <input
          type="text"
          [(ngModel)]="valorClp"
          (keyup)="transformCurrency('clp')"
          placeholder="$"
          pattern="[0-9]*"
          (keypress)="soloNumeros($event)"
          maxlength="11"
          >
        <span class="button-inline flex flex-row items-center">
          <button *ngIf="valorClp!=''" class="close-btn hover:border-gray-700/50 border-transparent border-1"(click)="clearValAvailableCLP()">✕</button>
        </span>
        <span>Tu envias CLP</span>

      </div>
      <img src="assets/img/transaccion.png" class="mb-5">
      <div class="grid grid-cols-1">
        <input
          type="text"
          [(ngModel)]="valorFiat"
          (keyup)="transformCurrency('fiat')"
          placeholder="$"
          pattern="[0-9]*"
          (keypress)="soloNumeros($event)"
          maxlength="11"
        >
        <span>Tu recibes VES</span>
        <mat-error *ngIf="minError">El monto mínimo para enviar es de {{minValue | currency}} CLP</mat-error>
        <mat-error *ngIf="maxError">El monto maximo para enviar es de {{_limitSell| currency}} CLP</mat-error>
      </div>
    </div>
  </div>
  <div class="flex flex-row gap-4 justify-center items-center my-7">
    <button
      mat-raised-button color="primary"
      class="w-40 md:w-30 mt-10 p-5"
      type="button"
      (click)="goToBack()">
      Volver
      </button>
    <button [disabled]="clpCurrency === 0 || vesCurrency === 0 || maxError || minError" (click)="emitChangeStep()" mat-raised-button color="primary" class="mt-10 p-5 text-lg">
      Comprar
    </button>
  </div>
