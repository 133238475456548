import { Component } from '@angular/core';
import { CognitoService } from './auth/services/cognito.service';
import { getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { FirebaseApp } from '@angular/fire/app';
import { ActionsService } from './shared/services/actions.service';
import { TiposMensaje } from './shared/enums/tipos-mensaje.enum';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { Idle } from '@ng-idle/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'aguacate-wallet';

  token = '';
  constructor(
    private _idle: Idle,
    private afApp: FirebaseApp,
    private cognitoService: CognitoService,
    private router: Router,
    private actionsService: ActionsService) {

    this.requestPermission();
  }

  message: any = null;

  listen() {
    const messaging = getMessaging(this.afApp);
    onMessage(messaging, (payload: any) => {
      this.manageNotification(payload.data.action);
    });
  }

  requestPermission() {
    const messaging = getMessaging(this.afApp);
    getToken(messaging, { vapidKey: environment.firebase.applicationServerKey })
      .then((currentToken) => {
        if (currentToken) {
          this.token = currentToken;
          console.log(currentToken);
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  showMessage(title: string, iconName: SweetAlertIcon = 'success') {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 6000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: iconName,
      title: title,
    });
  }

  manageNotification(action: TiposMensaje) {
    if (action === TiposMensaje.RECARGAR_SALDO) {
      this.actionsService.emitirAccionRecargarSaldo();
      this.showMessage('Saldo abonado a tu cuenta');
    }
    if (action === TiposMensaje.ACTUALIZAR_TRANSACCIONES_ASIGNADAS) {
      this.actionsService.emitirRecargarTransaccionesAsignadas();
      this.showMessage('Transacciones seleccionadas por otro operador', 'info');
    }
    if (action === TiposMensaje.ACTUALIZAR_TASA_FIAT) {
      this.actionsService.emitirAccionTasaFiat();
    }
    if (action === TiposMensaje.ACTUALIZAR_USDT) {
      this.actionsService.emitirAccionTasaUSDT();
    }
  }

  parseQueryParams(queryString: string | undefined): { [key: string]: string } {
    if (!queryString) {
      return {};
    }

    return queryString.split('&').reduce((params: { [key: string]: string }, param: string) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
  }


  public ngOnInit(): void {


    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentRoute = event.urlAfterRedirects;
        this.cognitoService.isAuthenticated().then((isAuthenticated) => {
          if (isAuthenticated && currentRoute.includes('auth/login')) {
            this.router.navigate(['/wallet']);
          }
        });
      });
    this.listen();


  }


}
