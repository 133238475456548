<div class="p-4 flex flex-col md:p-14 gap-[1rem] mt-2">
  <ng-container *ngIf="userRole === userEnum.aguacate_retail_client">
    <ng-container>
      <div>
        <h1 class="ml-4 text-lg ">Hola, <span class="font-bold">{{user.nombre}}</span></h1>
      </div>
      <div class="p-3 md:p-6 bg-white rounded-lg shadow-md">
        <h2 class="text-2xl font-bold text-center mb-2">Tasas del dia</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
          <div class="bg-white p-6 rounded-lg shadow-lg" *ngFor="let rate of rates">
            <p class="text-aguacate-green whitespace-nowrap">
              <b>
                {{ rate.rango.split('-')[0] | currency:'USD':'$':'1.0-0' }} CLP - {{ rate.rango.split('-')[1] | currency:'USD':'$':'1.0-0' }} CLP
              </b>
            </p>
            <p class="text-sm">{{ rate.descripcion }}</p>

            <p class="text-lg font-bold text-aguacate-green">Tasa: {{ rate.tasa | number:'1.5-5' }}</p>
          </div>
        </div>
      </div>
      <h1 class="text-2xl font-bold mx-4 mt-4 mb-4">¿Qué deseas realizar hoy?</h1>
      <div class="w-full md:w-2/4 flex flex-row md:self-start gap-10 justify-start mb-4">
        <div class="flex flex-col items-center justify-between aguacate-icons" routerLink="/retail/exchange">
          <img src="./assets/icons/send_fiat.svg" class="w-[40px]">
          <p class="text-sm font-bold mt-2">Enviar Remesa</p>
        </div>

      </div>
      <hr>
    </ng-container>
    <app-transactions></app-transactions>
  </ng-container>

  <ng-container *ngIf="userRole === userEnum.aguacate_retail_cashier">
    <div class="p-4 flex flex-col md:px-14 md:py-8">
      <h1 class="ml-4 text-lg ">¡Bienvenido(a) al portal de transacciones!</h1>
    </div>
    <div class="flex flex-row flex-wrap justify-between">
      <div class="w-1/3 overflow-auto place-content-center min-w-[340px] max-w-[640px] max-h-[380px] shadow-md">
          <h1 class="text-2xl font-bold mx-4 text-center  h-[35px] md:h-[95px]">Operaciones por procesar</h1>
          <div class="p-2">
              <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                  <div class="clickable-card rounded-[25px] p-3 border-2 border-aguacate-green" (click)="redirect('/retail/transactions')">
                      <h2 class="text-lg font-bold" [ngClass]="{
                          'blinking-text': this.pendingCount && this.pendingCount?.AC>30,
                          'blinking-text2': this.pendingCount && this.pendingCount?.AC<=30&&this.pendingCount?.AC>15
                        }">
                          AC bolivares
                      </h2>
                      <p [ngClass]="{
                          'blinking-text': this.pendingCount && this.pendingCount?.AC>30,
                          'blinking-text2': this.pendingCount && this.pendingCount?.AC<=30&&this.pendingCount?.AC>15
                        }">{{this.pendingCount ? this.pendingCount?.AC : 'Cargando'}}</p>
                      <h4 class="italic">
                          Pendientes
                      </h4>
                  </div>
              </div>
          </div>
  
      </div>
  
      <div class="w-1/3 overflow-auto place-content-center min-w-[340px] max-w-[640px] max-h-[380px] shadow-md">
          <h1 class="text-2xl font-bold mx-4 text-center  h-[95px]">Operaciones completadas hoy
              {{formatDate(todayDate) }}</h1>
          <div class="p-2">
              <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                  <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]" (click)="redirect('/retail/completed-ves-transactions')">
                      <h2 class="text-lg font-bold">
                          AC bolivares
                      </h2>
                      <p>{{this.completedCount?.currentDay ? this.completedCount?.currentDay.AC : 'Cargando'}}</p>
                      <h4 class="italic">
                          Completadas
                      </h4>
                  </div>
              </div>
          </div>
  
      </div>
      <div class="w-1/3 overflow-auto place-content-center min-w-[340px] max-w-[640px] max-h-[380px] shadow-md">
          <h1 class="text-2xl font-bold mx-4 text-center h-[95px]">Operaciones completadas ayer
              {{formatDate(yesterdayDate) }}</h1>
          <div class="p-2">
              <div class="w-full flex flex-row gap-2 flex-wrap text-center place-content-center">
                  <div class="rounded-[25px] border-2 border-aguacate-green p-3 w-[150px]" (click)="redirect('/retail/completed-ves-transactions')">
                      <h2 class="text-lg font-bold">
                          AC bolivares
                      </h2>
                      <p>{{this.completedCount?.previousDay ? this.completedCount?.previousDay.AC : 'Cargando'}}</p>
                      <h4 class="italic">
                          Completadas
                      </h4>
                  </div>
              </div>
          </div>
  
      </div>
  </div>

  <div class="p-3 md:p-6 bg-white rounded-lg shadow-md">
        <h2 class="text-2xl font-bold text-center mb-2">Tasas del dia</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
          <div class="bg-white p-6 rounded-lg shadow-lg" *ngFor="let rate of rates">
            <p class="text-aguacate-green whitespace-nowrap">
              <b>
                {{ rate.rango.split('-')[0] | currency:'USD':'$':'1.0-0' }} CLP - {{ rate.rango.split('-')[1] | currency:'USD':'$':'1.0-0' }} CLP
              </b>
            </p>
            <p class="text-sm">{{ rate.descripcion }}</p>

            <p class="text-lg font-bold text-aguacate-green">Tasa: {{ rate.tasa | number:'1.5-5' }}</p>
          </div>
        </div>
      </div>
  </ng-container>
</div>