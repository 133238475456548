import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { loadUserWithEmail } from 'src/app/auth/auth.actions';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { User } from 'src/app/shared/models/user.model';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Country } from '../../interfaces/country.interface';
import { CountriesService } from '../../services/countries.service';
import { UserService } from '../../services/user.service';
import { MaxSizeValidator } from '@angular-material-components/file-input';
import { Observable, from, map, of, startWith } from 'rxjs';
import { S3 } from 'aws-sdk';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { validateRut } from '@fdograph/rut-utilities';

@Component({
  selector: 'app-validate-user',
  templateUrl: './validate-user.component.html',
  styleUrls: ['./validate-user.component.sass'],
})
export class ValidateUserComponent {
  email: string = '';
  datosForm: FormGroup = new FormGroup({});;
  tiposID = [ 'PASAPORTE', 'RUT'];
  paises: Country[] = [];
  optionsPaisResidencia: Observable<Country[]> | undefined;
  optionsPrefijoTelefono: Observable<Country[]> | undefined;
  optionsNacionalidad: Observable<Country[]> | undefined;
  maxDate: Date= new Date();
  isCompany: boolean= false;
  sexos = ['Masculino', 'Femenino'];
  constructor(
    private cognitoService: CognitoService,
    private store: Store<AppState>,
    private router: Router,
    private __snackBar: MatSnackBar,
    private fb: FormBuilder,
    private countryService: CountriesService,
    private userService: UserService,
    private spinner: SpinnerService
  ) {
   this.initForm();
  }

  async initForm(){
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    await this.loadUserData();

    this.datosForm = this.fb.group({
      nombre: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      apellido: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      tipoId: new FormControl('', [Validators.required]),
      numeroId: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      nacionalidad: new FormControl('', this.isCompany ? [] : [Validators.required]),
      prefijoTelefono: new FormControl('+56', [Validators.required]),
      telefono: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      sexo: new FormControl('', this.isCompany ? [] : [Validators.required]),
      fechaNacimiento: new FormControl('', this.isCompany ? [] : [Validators.required]),
      ciudadResidencia: new FormControl('',this.isCompany ? [] : [Validators.required, Validators.maxLength(30)]),
      paisResidencia: new FormControl('', this.isCompany ? [] : [Validators.required]),
      direccion: new FormControl('', this.isCompany ? [] : [Validators.required, Validators.maxLength(60)]),
      profesion: new FormControl('', this.isCompany ? [] : [Validators.required, Validators.maxLength(30)]),
      documentoFrontal: new FormControl('', [Validators.required, MaxSizeValidator(5 * 1000000)]),
      documentoPosterior: new FormControl('', [Validators.required, MaxSizeValidator(5 * 1000000)]),
      politicamenteExpuesto: new FormControl(false, this.isCompany ? [] : [Validators.required]),
      documentoIdTributario: new FormControl('',  this.isCompany ? [Validators.required, MaxSizeValidator(5 * 1000000)]: []),
      estatuto: new FormControl('',  this.isCompany ? [Validators.required, MaxSizeValidator(5 * 1000000)]: []),
      razonSocial:  new FormControl('',this.isCompany ? [Validators.required, Validators.maxLength(40)]: []),
      rutEmpresa: new FormControl('',this.isCompany ?  [Validators.required, Validators.maxLength(15)]: []),
      nombreComercial:  new FormControl('',this.isCompany ? [Validators.required, Validators.maxLength(40)]: []),
      domicilioFiscal: new FormControl('', !this.isCompany ? [] : [Validators.required, Validators.maxLength(60)]),

    });

    this.datosForm.get('documentoFrontal')?.valueChanges.subscribe(res=>{
      this.fileCheck('documentoFrontal');
    })
    this.datosForm.get('documentoPosterior')?.valueChanges.subscribe(res=>{
      this.fileCheck('documentoPosterior');
    })

    this.datosForm.get('estatuto')?.valueChanges.subscribe(res=>{
      this.fileCheck('estatuto', true);
    })
    this.datosForm.get('documentoIdTributario')?.valueChanges.subscribe(res=>{
      this.fileCheck('documentoIdTributario',  true);
    })
    this.countryService.getCountryList().subscribe((countryList) => {
      this.paises = countryList;

    });
    this.optionsPrefijoTelefono = this.datosForm.controls['prefijoTelefono'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.optionsPaisResidencia = this.datosForm.controls['paisResidencia'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.optionsNacionalidad = this.datosForm.controls['nacionalidad'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  getFormValidationErrors() {
    Object.keys(this.datosForm.controls).forEach(key => {
      const controlErrors = this.datosForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  async loadUserData() {
    const ref = this.spinner.start();
    const userInSession = await this.cognitoService.getUser();
    this.email = userInSession.attributes['email'];
    this.isCompany = Boolean(Number(userInSession.attributes['custom:is-company'])) ?? false;
    this.spinner.stop(ref);
  }

  validateRut(){
    if ( this.datosForm.controls['numeroId'].hasError('incorrectFormat')) {
      this.datosForm.controls['numeroId'].setErrors({incorrectFormat: null});
      this.datosForm.controls['numeroId'].updateValueAndValidity();
    }
    if(this.datosForm.controls['tipoId'].value !== 'RUT'){
      return;
    }
    const rut = this.datosForm.controls['numeroId'].value;
    if(validateRut(rut)){
      this.datosForm.controls['numeroId'].setErrors({incorrectFormat: null});
      this.datosForm.controls['numeroId'].updateValueAndValidity();
    }else{
      this.datosForm.controls['numeroId'].setErrors({incorrectFormat: true});
    }
  }

  validateRutEmpresa(){
    if ( this.datosForm.controls['rutEmpresa'].hasError('incorrectFormat')) {
      this.datosForm.controls['rutEmpresa'].setErrors({incorrectFormat: null});
      this.datosForm.controls['rutEmpresa'].updateValueAndValidity();
    }

    const rut = this.datosForm.controls['rutEmpresa'].value;
    if(validateRut(rut)){
      this.datosForm.controls['rutEmpresa'].setErrors({incorrectFormat: null});
      this.datosForm.controls['rutEmpresa'].updateValueAndValidity();
    }else{
      this.datosForm.controls['rutEmpresa'].setErrors({incorrectFormat: true});
    }

  }

  async fileCheck(formControlName: string, acceptPDF = false){
    this.datosForm.controls[formControlName].setErrors(null);
    let isValid = false;
    const file = this.datosForm.controls[formControlName].value;
    const isPNG = this.check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
    const isJPEG = this.check([0xff, 0xd8, 0xff]);
    const isPDF = this.check([0x25, 0x50, 0x44, 0x46, 0x2D]);
      const buffers: Buffer = await this.readBuffer(file, 0, 8);
      const uint8Array = new Uint8Array(buffers);
      if(isPNG(uint8Array)){
        isValid = true;
      }
      if(isJPEG(uint8Array)){
        isValid = true;
      }
      if(acceptPDF && isPDF(uint8Array)){
        isValid = true;
      }
     if(!isValid){
      this.datosForm.controls[formControlName].setErrors({incorrectFormat: true});
     }
  }

  readBuffer(file: File, start = 0, end = 2): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.slice(start, end));
    });
  }

  check(headers: any) {
    return (buffers: any, options = { offset: 0 }) =>
      headers.every(
        (header:any, index: any) => header === buffers[options.offset + index]
      );
  }
  checkPrefijo() {
    setTimeout(()=> {
     if (this.paises.findIndex((elem : Country) => elem.DIAL === this.datosForm.controls['prefijoTelefono'].value) === -1) {
      this.datosForm.controls['prefijoTelefono'].setValue(null);
     }
    }, 500);
   }

   checkPais(formControlName: string) {
    setTimeout(()=> {
     if (this.paises.findIndex((elem : Country) => elem.NAME === this.datosForm.controls[formControlName].value) === -1) {
      this.datosForm.controls[formControlName].setValue(null);
     }
    }, 500);
   }

  private _filter(value: string): Country[] {
    if(!value)return this.paises;
    const filterValue = value.toLowerCase();

    return this.paises.filter(option => option.NAME.toLowerCase().includes(filterValue) || option.DIAL.includes(filterValue));
  }
  async enviarDatos() {
    this.getFormValidationErrors();
    if (!this.datosForm.valid) return;
    let username = await this.cognitoService.getUser();
    username = username.username;

    const spinnerRef = this.spinner.start();
    const imagenPosterior = (await this.uploadImagesToS3('documentoPosterior')).Key;
    const imagenFrontal = (await this.uploadImagesToS3('documentoFrontal')).Key;
    let documentoIdTributario = '';
    let estatuto = '';

    let tipoUsuarioID=0;
    if(this.isCompany){
      documentoIdTributario = (await this.uploadImagesToS3('documentoIdTributario')).Key;
      estatuto = (await this.uploadImagesToS3('estatuto')).Key;
      tipoUsuarioID=1;
    }
    const result = this.datosForm.value;
    delete result.documentoFrontal;
    delete result.documentoPosterior;
    const user: User = { ...this.datosForm.value, documentoIdTributario, estatuto, tipoUsuarioID, cognitoId: username, email: this.email, fotoIdfrontal: imagenFrontal, fotoIdPosterior: imagenPosterior };
    user.numeroId = user.numeroId.replaceAll('.', '').replaceAll('-', '').toUpperCase();
    user.rutEmpresa =  user.rutEmpresa.replaceAll('.', '').replaceAll('-', '').toUpperCase();
    const payload = {
      user,
    };

    this.userService.sendUserVerification(payload).then(
      (response) => {
        console.log(response);
        this.spinner.stop(spinnerRef);
        this.store.dispatch(loadUserWithEmail({ email: this.email }));
        Swal.fire({
          icon: 'success',
          title: 'ENVIADO EXITOSAMENTE',
          text: 'Bienvenido al equipo de Aguacate, en pocos minutos estarás habilitado para empezar a usar nuestros servicios, te avisaremos a tu correo electrónico',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#1e5a3f',
        }).then(()=>{
          this.router.navigate(['/wallet/home']).then(()=>{
            window.scrollTo(0, 0);
          });
        });

      },
      (err) => {
        console.log(err);
        const msj = err?.error?.error || 'No se ha podido enviar tu solicitud, si el error persiste comuníquese con nosotros'
        this.spinner.stop(spinnerRef);
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
          text: msj,
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Ententido',
          confirmButtonColor: '#1e5a3f',

        });
      }
    );
  }

  async uploadImagesToS3(formControlName: string){
    const user = await this.cognitoService.getUser();
    const ext= this.datosForm.get(formControlName)?.value.name.split('.').pop();
    const s3 = new S3({
      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
    });
    const paramsFrontal = {
      Bucket: environment.s3.bucket,
      Key: `${user.username}${formControlName}.${ext}`,
      Body: this.datosForm.get(formControlName)?.value,
    };
    var promise = await s3.upload(paramsFrontal).promise();
    return promise;

  }


}
