import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fiat-change',
  templateUrl: './fiat-change.component.html',
  styleUrls: ['./fiat-change.component.sass']
})
export class FiatChangeComponent implements OnInit {

  @Input()
  public ves_buy_rate: number = 0;
  @Input()
  public ves_sell_rate: number = 0;
  @Input()
  public showBuy: boolean = true;
  @Input()
  public invert: boolean = false;
  public hasLoaded: boolean = true;

  constructor() {

  }

  ngOnInit(): void { }

}
