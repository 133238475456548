<div class="m-8">
    <div class="flex flex-col justify-between items-center">
      <div class="mb-10">
        <h1 class="text-xl font-bold">Resumen de Orden</h1>
      </div>
    </div>
    <div class="containerInfo w-full md:w-1/2 mx-auto">
      <p><span style="font-weight: bold;">Tu envias:</span> {{ clpCurrency | currency:'':'' }} CLP</p>
      <p><span style="font-weight: bold;">Tu beneficiario recibe:</span> {{ vesCurrency | currency:'':'' }} VES</p>
      <p><span style="font-weight: bold;">Beneficiario:</span> {{ beneficiario?.nombre }} {{beneficiario?.apellidos}}</p>
      <p><span style="font-weight: bold;">Banco:</span> {{ beneficiario?.banco?.nombre }} </p>
      <p *ngIf="beneficiario?.banco?.codigoBanco ==='BOFA'"><span style="font-weight: bold;">Número cuenta:</span> {{ beneficiario?.numeroCuenta }} </p>
      <p class="transaction-detail"><span style="font-weight: bold;">Número Cuenta:</span> {{ beneficiario?.numeroCuenta }}</p>
      <p><span style="font-weight: bold;">Tasa de compra CLP/VES:</span> {{ ves_clp_tax  }}</p>
    </div>

    <form [formGroup]="datosForm" class="w-full my-4" *ngIf="datosForm">
      <div class="grid grid-cols-1 gap-4">

        <mat-form-field appearance="fill">
          <mat-label>Comprobante de pago</mat-label>
          <div class="fileUploadContainer" [ngStyle]="{'margin-top' : datosForm.get('photo')!.value ? '5px' :  '20px'}">
            <ng-container *ngIf="datosForm.get('photo')!.value">
              <img [src]="datosForm.get('photo')!.value" />
              <button class="deleteButton" mat-icon-button (click)="fileInput.value = ''; datosForm.get('photo')?.setValue(null);">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>

            <div *ngIf="!datosForm.get('photo')!.value" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-icon style="opacity: 60%;">file_upload</mat-icon>
              <button mat-raised-button color="primary" style="width:100%; opacity: 80%;">Adjuntar</button>
              <small style="margin: 20px">Arrastrar y soltar</small>
            </div>

            <input #fileInput class="fileInput" type="file" multiple="false" accept="image/*"
              (change)="setFileData($event)" />
          </div>
          <input matInput formControlName="photo" readonly [hidden]="true " />
        </mat-form-field>
      </div>

    </form>
    <div class="flex flex-row justify-center items-center mt-10 mx-auto">
      <button
      mat-raised-button color="primary"
      class="w-40 py-7 md:w-30 mb-10 my-4 p-5"
      type="button"
      (click)="goToBack()">
      Volver
      </button>
      <button
        mat-raised-button color="primary"
        class="w-40 py-7 md:w-60 mb-10 my-4 ml-10 p-5"
        type="button"
        (click)="confirmarEnvio()">
        Enviar transacción
      </button>
    </div>
  </div>
