import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RatesAcService {

  private baseUrl = environment.apiGatewayRetailUrl;

  constructor(private http: HttpClient) {}

  getAllRates(): Promise<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}rates-Ag/all`;
    return firstValueFrom(this.http.post<any[]>(serviceURL,{},{ headers }))
  }

  getRatesByProfile(profile: string): Promise<any[]>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}/rates-Ag/lasts`;
    return firstValueFrom(this.http.post<any[]>(serviceURL, { perfil: profile }, { headers }))
      .then(rates => rates.sort((a, b) => {
      const rangeA = parseInt(a.rango.split('-')[0], 10);
      const rangeB = parseInt(b.rango.split('-')[0], 10);
      return rangeA - rangeB;
      }));
  }

  createRates(rates: any): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const serviceURL = `${this.baseUrl}/rates-Ag`;
    return firstValueFrom(this.http.post<any>(serviceURL, rates, { headers }))
  }
}

