import { Component, OnInit } from '@angular/core';
import { TransaccionService } from '../../services/transaccion.service';
import { Transaccion } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { AssignTransactionComponent } from './assign-transaction/assign-transaction.component';
import { getColumns, columns, displayedColumns } from './table-transactions-columns';
import { GetTransactionsType } from 'src/app/shared/enums/get-transactions-type';
import { CurrencyPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { S3 } from 'aws-sdk';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-admin-transactions',
  templateUrl: './admin-transactions.component.html',
  styleUrls: ['./admin-transactions.component.sass'],
})
export class AdminTransactionsComponent implements OnInit {
  transacciones: Transaccion[] = [];
  seleccionados: number = 0;
  dataSource: MatTableDataSource<any> | undefined;
  subscription: Subscription = new Subscription();
  columns = getColumns(this.currencyPipe);
  displayedColumns = displayedColumns;
  private intervalId: any;
  constructor(
    private transactionService: TransaccionService,
    private dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
  ) { }
  async ngOnInit() {

    await this.loadTransactions();

    this.intervalId = setInterval(() => {
      this.loadTransactions();
    }, 60000)
  }

  async loadTransactions() {
    if(this.transacciones.length !== 0){
      const transaccion_old: Transaccion[] = this.transacciones,
      transaccion_new: Transaccion[] = await this.transactionService.getAllTransactions(GetTransactionsType.PENDING);
      if (!this.transaccionesIguales(transaccion_old, transaccion_new)) {
        this.transacciones = transaccion_new;
        console.log('Las transacciones han cambiado.');
        Swal.fire({
          icon: 'info', // Icono de éxito
          title: 'Atención!',
          text: 'Las transacciones han actualizado.',
        });
      }
    }else{
      this.transacciones = await this.transactionService.getAllTransactions(GetTransactionsType.PENDING);
    }
    this.dataSource = new MatTableDataSource(this.transacciones);
  }


  private transaccionesIguales(transacciones1: Transaccion[], transacciones2: Transaccion[]): boolean {
    if (transacciones1.length !== transacciones2.length) {
      return false; // Si las longitudes son diferentes, las listas no son iguales
    }
    const transacciones1Ids = transacciones1.map((t) => t.id);
    const transacciones2Ids = transacciones2.map((t) => t.id);

    // Compara los identificadores de las transacciones
    for (const id of transacciones1Ids) {
      if (!transacciones2Ids.includes(id)) {
        return false; // Si un identificador en transacciones1 no está en transacciones2, no son iguales
      }
    }
    return true; // Si todas las comparaciones fueron iguales, las listas son iguales
  }

  change() {
    this.seleccionados =
      this.dataSource?.data.filter((value) => value.seleccionado).length || 0;

  }

  limpiarSeleccion() {
    const data =
      this.dataSource?.data.map((value) => {
        return { ...value, seleccionado: false };
      }) || [];

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data = data;
    }
    this.change();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.intervalId);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
  }

  public doFilter = (event: any) => {
    const element = event.currentTarget as HTMLInputElement;
    const value = element.value;
    if (this.dataSource)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
  //TPDP

  returnSelectedTransactions(row: Transaccion) {
    const selected =
      this.dataSource?.data?.filter((x) => x.seleccionado && x.id !== row.id) ||
      [];
    selected.push(row);
    return selected;
  }
  openImageDialog(imageUrl: string) {

    const s3 = new S3({
      region: environment.s3.region,
      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
    });
    const signedUrl = s3.getSignedUrl('getObject', {
      Bucket: environment.s3.bucket,
      Key: imageUrl,
      Expires: 500, // URL expiration time in seconds
    });

    imageUrl = signedUrl;
    console.log(signedUrl);
    Swal.fire({
      imageUrl: imageUrl,
      imageAlt: 'Transaction Image',
      showCloseButton: true,
      showConfirmButton: false,
      width: 'auto',
    });
  }

  rejectTransaction(row: Transaccion) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to reject this transaction?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reject it!',
      input: 'text',
      inputPlaceholder: 'Enter reason for rejection',
      inputValidator: (value) => {
        return value ? null : 'You need to write something!';
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        const rejectDTO = {
          transactionID: row.id,
          motivo: result.value,
        };
        try {
          await this.transactionService.rejectTransaction(rejectDTO);
          Swal.fire('Rejected!', 'The transaction has been rejected.', 'success');
          this.loadTransactions();
        } catch (error) {
          Swal.fire('Error!', 'There was an error rejecting the transaction.', 'error');
        }
      }
    });
  }
  openAssignDialog(row: any) {
    const selectedTransactions = this.returnSelectedTransactions(row);
    const dialogRef = this.dialog.open(AssignTransactionComponent, {
      data: {
        selectedTransactions,
      },
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
      // if(result == true && this.usuarioID){
      //   this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      // }
    });
  }
}
