
<mat-stepper (selectionChange)="onStepChange($event)" #stepper class="pt-4 py-4" [orientation]="isVertical ? 'vertical': 'horizontal'" [linear]="true">
    <h2>Horario de operaciones Zelle: 9:00 - 17:00 </h2>
    <mat-step [completed]="stepPass2 && beneficiaryComponent!.beneficiarioEscogido != undefined">
      <ng-template matStepLabel>Elige tu beneficiario</ng-template>
      <app-beneficiary-fiat
        #beneficiarioComponent
        (changeStep)="nextStep($event)"
        (beneficiarioOutput)="assignBeneficiario($event)"
        (goBack)="backStep()"></app-beneficiary-fiat>
    </mat-step>
    <mat-step [completed]="stepPass3 && (+clpCurrency !=0 && +vesCurrency != 0)">
      <ng-template matStepLabel>Calcula tu cambio</ng-template>
      <div class="w-full md:w-1/2 mx-auto mt-10">
        <app-calculator-fiat-cal
        #calculatorComponent
        (clpCurrencyEmit)="assignClp($event)"
        (vesCurrencyEmit)="assignZelle($event)"
        (changeStep)="nextStep($event)"
        (goBack)="backStep()"
        [ves_buy_rate]="vesSellValue"
        [minLimit]="minLimit"
        [limitSell]="limitFiatSell"
        [rateList]="rateList"
        >
      </app-calculator-fiat-cal>
      </div>
    </mat-step>
    <mat-step [completed]="stepPass4">
      <ng-template matStepLabel>Resumen de tu orden</ng-template>
      <app-summary-order-fiat
        [clpCurrency]="clpCurrency"
        [vesCurrency]="vesCurrency"
        [ves_clp_tax]="vesSellValue"
        [beneficiario]="beneficiario"
        [valorCambioId]="valorCambioId"
        (goBack)="backStep()"
        ></app-summary-order-fiat>
    </mat-step>
  </mat-stepper>
