import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TransactionsAcServiceService } from '../../services/transactions-ac-service.service';

@Component({
  selector: 'app-completed-transactions-ac',
  templateUrl: './completed-transactions-ac.component.html',
  styleUrls: ['./completed-transactions-ac.component.sass']
})
export class CompletedTransactionsAcComponent implements OnInit {
  transacciones: any;

  columns = [
    {
      columnDef: 'id',
      header: 'ID',
      cell: (element: any) => `${element.id}`,
    },
    {
      columnDef: 'comprobante',
      header: 'Comprobante',
      cell: (element: any) => `${element.comprobante}`,
    },
    {
      columnDef: 'totalMonedaOrigen',
      header: 'Total Moneda Origen',
      cell: (element: any) => `${element.totalMonedaOrigen}`,
    },
    {
      columnDef: 'totalMonedaDestino',
      header: 'Total Moneda Destino',
      cell: (element: any) => `${element.totalMonedaDestino}`,
    },
    {
      columnDef: 'monedaOrigen',
      header: 'Moneda Origen',
      cell: (element: any) => `${element.monedaOrigen}`,
    },
    {
      columnDef: 'monedaDestino',
      header: 'Moneda Destino',
      cell: (element: any) => `${element.monedaDestino}`,
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (element: any) => `${element.estado}`,
    },
    {
      columnDef: 'createdAt',
      header: 'Fecha de Creación',
      cell: (element: any) => `${this.datePipe.transform(element.createdAt, 'dd/MM/yyyy')}`,
    },
    {
      columnDef: 'paisOrigen',
      header: 'País Origen',
      cell: (element: any) => `${element.paisOrigen}`,
    },
    {
      columnDef: 'paisDestino',
      header: 'País Destino',
      cell: (element: any) => `${element.paisDestino}`,
    },
    {
      columnDef: 'userId',
      header: 'Usuario ID',
      cell: (element: any) => `${element.userId}`,
    },
    {
      columnDef: 'tasa',
      header: 'Tasa',
      cell: (element: any) => `${element.tasa}`,
    },
    {
      columnDef: 'cuentaBeneficiarioId',
      header: 'Cuenta Beneficiario ID',
      cell: (element: any) => `${element.cuentaBeneficiarioId}`,
    },
    {
      columnDef: 'precio_compra',
      header: 'Precio Compra',
      cell: (element: any) => `${element.precio_compra}`,
    },
    {
      columnDef: 'cuentaTrabajoID',
      header: 'Cuenta Trabajo ID',
      cell: (element: any) => `${element.cuentaTrabajoID}`,
    }
  ];
  displayedColumns = [...this.columns.map(column => column.columnDef), 'actions'];

  dataSource: MatTableDataSource<any> | undefined;
  tasas: any;

  constructor(private datePipe: DatePipe, private spinner: SpinnerService, private transactionsAcService: TransactionsAcServiceService
  ) {
  }
  ngOnInit(): void {
    this.initTasaValue();
  }

  async initTasaValue(): Promise<void> {
    const ref = this.spinner.start();
    this.transacciones = await this.transactionsAcService.getAllCompletedTransactionsAc();
    this.dataSource = new MatTableDataSource(this.transacciones);
    this.spinner.stop(ref);
  }
  
  async reload(): Promise<void> {
    this.initTasaValue();
  }

}
