import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { AccountRecipientService } from '../../services/account-recipient.service';
import { CuentaTrabajo } from '../../interfaces/cuenta-trabajo.interface';
import { WorkingAccountService } from '../../services/working-account.service';
import { AddWorkingAccountComponent } from './add-working-account/add-working-account.component';

@Component({
  selector: 'app-working-account',
  templateUrl: './working-account.component.html',
  styleUrls: ['./working-account.component.sass']
})
export class WorkingAccountComponent {
  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  listaCuentas: CuentaTrabajo[] = [];
  @Input() seleccionable = false;
  @Output() seleccion: EventEmitter<CuentaTrabajo> = new EventEmitter();
  countryList = ['VE', 'US', 'CL'];
  constructor(
    private store: Store<AppState>,
    private workingAccountService: WorkingAccountService,
    private dialog: MatDialog
  ) {
    this.initFormCuenta();
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    this.listaCuentas = await this.workingAccountService.getWorkingAccounts(this.countryList);
  }
  ngOnInit() {}

  async reloadAccount(){
    this.listaCuentas = await this.workingAccountService.getWorkingAccounts(this.countryList);
  }

  openCreateDialog(cuenta?: CuentaTrabajo):void{
    const dialogRef = this.dialog.open(AddWorkingAccountComponent, {
      data: {
        type: cuenta? 'UPDATE': 'CREATE',
        cuenta
      },
      height: '70%'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result == true){
        await this.reloadAccount();
      }
    })
  }

  onSeleccion(cuenta: CuentaTrabajo){
    this.seleccion.emit(cuenta);
  }

}
